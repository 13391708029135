// import dynamic from 'next/dynamic'
import mapSliceToComponent from '~/imports/home_page'
import { PAGE } from '~/lib/fetch-links'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

import SliceManager from '~/components/UI/SliceManager'

import { ClientPageView } from '~/providers/GTMTrackingProvider/ClientPageView'

import { getStaticPropsFunction } from '~/data/page-data'
import { DefaultPageData } from '~/data/page-data/serializer'

function HomePage({ slices }: DefaultPageData) {
  return (
    <>
      <SliceManager
        mapSliceTypeToComponent={mapSliceToComponent}
        slicesData={slices}
      />
      <ClientPageView template="index" />
    </>
  )
}

export default HomePage

export const getStaticProps = getStaticPropsFunction({
  type: PRISMIC_TYPES.HOMEPAGE,
  fetchLinks: PAGE,
})
