import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Image, { ImageProps } from '~/components/UI/Image'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import { isRTFilled } from '~/utils/check-empty-string'
import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  BIG: [{ breakpoint: 'md', ratio: 12 / 24 }, { ratio: 1 }],
  SMALL: [{ breakpoint: 'md', ratio: 4 / 24 }, { ratio: 12 / 24 }],
}

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title30_35HafferSemiBold,
  [GlobalTextSize.Small]: GlobalTextPreset.Title18_24HafferSemiBold,
}

export interface PushCoverProps {
  className?: string
  imageBig?: ImageProps
  imageSmall?: ImageProps
  subtitle?: RichTextBlocks
  title?: RichTextBlocks
  backgroundColor?: string
  cta?: SquaredCtaProps
  reversed?: boolean
  titleSize?: GlobalTextSize
}

function PushCover({
  className,
  imageBig,
  imageSmall,
  subtitle,
  title,
  backgroundColor,
  cta,
  reversed,
  titleSize,
}: PushCoverProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const titleSizeStyle = getCSSThemeClassName(css, 'titleSize', titleSize)

  const backgroundColorStyle = backgroundColor ? { backgroundColor } : {}

  const hasContent =
    imageSmall || isRTFilled(subtitle) || isRTFilled(title) || cta

  const bigImageProps = {
    sizesFromBreakpoints: IMAGE_SIZES.BIG,
    asPlaceholder: true,
    ...imageBig,
  }

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={ref}
      className={cx(css.PushCover, className, gridStyle, titleSizeStyle, {
        reversed,
      })}
      style={{ ...backgroundColorStyle }}>
      {imageBig && (
        <>
          <Image
            className={cx(css.image, css.big, css.hideOnSmallScreen)}
            layout="fill"
            objectFit="cover"
            priority={slicePosition === 1}
            {...bigImageProps}
          />
          <Image
            className={cx(css.image, css.big, css.hideOnLargeScreen)}
            priority={slicePosition === 1}
            {...bigImageProps}
          />
        </>
      )}

      {hasContent && (
        <div className={cx(css.content)}>
          <RichText
            className={cx(css.subtitle, subtitleStyle)}
            render={subtitle}
          />
          <RichText className={cx(css.title, titleStyle)} render={title} />

          {imageSmall && (
            <Image
              className={cx(css.image, css.small)}
              ratio={css.ratio}
              sizesFromBreakpoints={IMAGE_SIZES.SMALL}
              layout="fill"
              objectFit="cover"
              asPlaceholder
              priority={slicePosition === 1}
              {...imageSmall}
            />
          )}

          {cta && <SquaredCta className={cx(css.cta)} {...cta} />}
        </div>
      )}
    </div>
  )
}

PushCover.defaultProps = {
  titleSize: GlobalTextSize.Big,
}

export default PushCover
