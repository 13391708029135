import { PRISMIC_SLICES } from '~/lib/prismic-types'

import CoverImage from '~/components/Slices/CoverImage'
import FormEventSlice from '~/components/Slices/FormEventSlice'
import HeroMain from '~/components/Slices/HeroMain'
import HeroMainTeasing from '~/components/Slices/HeroMainTeasing'
import HeroMainTeasingScroll from '~/components/Slices/HeroMainTeasingScroll'
import ProductsGrid from '~/components/Slices/ProductsGrid'
import ProductsGridEdito from '~/components/Slices/ProductsGridEdito'
import ProductsSlider from '~/components/Slices/ProductsSlider'
import PushCover from '~/components/Slices/PushCover'
import PushImage from '~/components/Slices/PushImage'
import PushProductsImages from '~/components/Slices/PushProductsImages'
import StoriesSlider from '~/components/Slices/StoriesSlider'
import VideoPlayer from '~/components/Slices/Video'

export default {
  [PRISMIC_SLICES.COVER_IMAGE]: CoverImage,
  [PRISMIC_SLICES.FORM_EVENT_SLICE]: FormEventSlice,
  [PRISMIC_SLICES.HERO_MAIN_TEASING_SCROLL]: HeroMainTeasingScroll,
  [PRISMIC_SLICES.HERO_MAIN_TEASING]: HeroMainTeasing,
  [PRISMIC_SLICES.HERO_MAIN]: HeroMain,
  [PRISMIC_SLICES.PUSH_COVER]: PushCover,
  [PRISMIC_SLICES.PUSH_IMAGE]: PushImage,
  [PRISMIC_SLICES.STORIES_SLIDER]: StoriesSlider,
  [PRISMIC_SLICES.VIDEO_PLAYER]: VideoPlayer,
  // [PRISMIC_SLICES.PUSH_PRODUCTS_IMAGES]: PushProductsImages,
  // [PRISMIC_SLICES.PRODUCTS_GRID_EDITO]: ProductsGridEdito,
  // [PRISMIC_SLICES.PRODUCTS_GRID]: ProductsGrid,
  // [PRISMIC_SLICES.PRODUCTS_SLIDER]: ProductsSlider,
  [PRISMIC_SLICES.PRODUCTS_SLIDER_V2]: ProductsSlider,
  [PRISMIC_SLICES.PRODUCTS_GRID_V2]: ProductsGrid,
  [PRISMIC_SLICES.PRODUCTS_GRID_EDITO_V2]: ProductsGridEdito,
  [PRISMIC_SLICES.PUSH_PRODUCTS_IMAGES_V2]: PushProductsImages,
}
