import { useRouter } from "next/router"
import { PageViewAnalytics } from "@unlikelystudio/react-ecommerce-hooks"

type ClientPageViewProps = {
  template: Parameters<typeof PageViewAnalytics>[0]["template"]
}

export function ClientPageView({ template }: ClientPageViewProps) {
  const { asPath } = useRouter()

  return <PageViewAnalytics pathname={asPath} template={template} />
}
